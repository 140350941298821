<template>
  <div class="back">
    <div class="main_con" v-for="(item, index) in mainList" :key="index">
      <img
        :class="$parent.watchClientWidth>600?'mainItem_img':'mainItem_img1'"
        :src="$parent.switchLanguage == 'zh' ? item.zhUrl : item.enUrl"
        alt=""
      />
    </div>
  </div>
</template>

<script>
const api = "https://website-1308146035.cos.ap-shanghai.myqcloud.com";
export default {
  name: "regards",
  data() {
    return {
      switchLanguage: "",
      mainList: [
        {
          zhUrl: api + "/regards%2Fmain_zh.png",
          enUrl:  api + "/regards%2Fmain_en.png"
        }
      ]
    };
  },
  mounted() {},
  methods: {}
};
</script>

<style lang="scss" scoped>
.main_con{
  .mainItem_img{
    width: 100%;
    height: 1980px;
  }
  .mainItem_img1{
    width: 100%;
    height: 450px;
  }
}
</style>
